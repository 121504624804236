import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import SEO from '@components/Seo';
const Blog = ({
    data: {
        blog: {
            html,
            frontmatter: { heading, image, description, read_time, date, tags, text },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`${heading} • Blog`} />
            <div className={styles.blogWrapper}>
                {/*<div className={`lg:h-screen w-full ${styles.bgWrapper}`}>*/}
                {/*    <BackgroundImage*/}
                {/*        */}
                {/*        */}
                {/*    />*/}
                {/*</div>*/}
                <div className={`max-w-6xl mx-auto relative`}>
                    <div className={`${styles.blog}`}>
                        <Img className={styles.bgImage} fluid={image?.childImageSharp.fluid} />
                        <div className={styles.blogHeading}>
                            <h1>{heading}</h1>
                            <p>{description}</p>

                            <p className={styles.blogHeadingLastUpdated}>
                                Last updated {date}.{' '}
                                <span className="font-bold">{read_time} min read.</span>
                            </p>

                            <div className={`${styles.blogHeadingTags} mt-4 -mb-2 flex flex-wrap`}>
                                {tags?.map(({ name, path }) => {
                                    return (
                                        <Link
                                            to={path}
                                            className={`mr-2 mb-2 cursor-pointer text-xs ${
                                                styles.tag
                                            } ${name.toLowerCase() === 'favourite' &&
                                                `${styles.fav}`}`}
                                        >
                                            {' '}
                                            <span>{name}</span>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                        <hr />
                        <div className={styles.blogContent}>
                            <div
                                className={styles.blogBody}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query($path: String) {
        blog: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                heading: blogs_blog_title
                image: blogs_blog_overview_image {
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                description: blogs_blog_overview_description
                date: blogs_blog_date
                read_time: blogs_blog_readtime
                tags: blogs_blog_tags {
                    name
                    path
                }
            }
        }
    }
`;
